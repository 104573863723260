body {
  margin: 0;
  font-weight: 400;
  font-size: 1em;
  font-family: "Raleway", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  max-width: 100%;
}

@keyframes fadeInUpMedium {
  from {
    opacity: 0;
    transform: translate3d(0, 200px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpMedium {
  animation-name: fadeInUpMedium;
  animation-delay: 0.5s;
}

@keyframes fadeInUpScaleY {
  from {
    opacity: 0;
    transform: scaleY(0);
    transform: translate3d(0, 200px, 0);
  }

  to {
    opacity: 1;
    transform: scaleY(1);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpScaleY {
  animation-name: fadeInUpScaleY;
  animation-delay: 0.5s;
}

@keyframes fadeInScaleX {
  from {
    opacity: 0;
    transform: scaleX(0);
  }

  to {
    opacity: 1;
    transform: scaleX(1);
  }
}

.fadeInScaleX {
  animation-name: fadeInScaleX;
  animation-delay: 0.5s;
}
